import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CRespuestaService } from '../login/shared/CRespuesta.service';
import { StorageService } from '../core/services/storage.service';
import { MatDialog } from '@angular/material';
import { MensajeErrorComponent } from '../mensaje-error/mensaje-error.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-loginrecuperacion',
  templateUrl: './loginrecuperacion.component.html',
  styleUrls: ['./loginrecuperacion.component.scss']
})
export class LoginrecuperacionComponent implements OnInit {

  cookie:string;
  loginForm: FormGroup;
  showSpinner: boolean = false;
  showForm:boolean=true;
  strUserName:string;

  constructor(private formBuilder: FormBuilder,private storageService: StorageService,private route: ActivatedRoute,
    private router: Router,private CRespuestaService:CRespuestaService,public dialog: MatDialog) {
      this.route.queryParams.subscribe(params => {
        this.cookie=params["coo"];
      });
     }

  ngOnInit() {

    this.loginForm  = this.formBuilder.group({
      username: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.maxLength(40),
        //Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ]))
    });    
  }


  keyDownFunction(event) {

    if(event.key === "Enter"){
      this.submitLogin();
    }
  }
  
submitLogin(){
  if(this.loginForm.valid){               
     
     this.CRespuestaService.envialEmailRecuperacionContrasena(this.strUserName,this.cookie).pipe(first()).subscribe((datos:any)=>{
       this.mensajeError(datos.strMensaje);           
     })
  }
}

 private mensajeError(data:any){
  let dialogRef = this.dialog.open(MensajeErrorComponent, {
    width: '600px',
    data: {
      'strMensaje':data
    }
  });
  dialogRef.afterClosed().subscribe(() => {
  });
 }

}
