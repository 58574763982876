import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CRespuestaService } from '../login/shared/CRespuesta.service';
import { StorageService } from '../core/services/storage.service';
import { MatDialog } from '@angular/material';
import { MensajeErrorComponent } from '../mensaje-error/mensaje-error.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-cambia-contrasena',
  templateUrl: './cambia-contrasena.component.html',
  styleUrls: ['./cambia-contrasena.component.scss']
})
export class CambiaContrasenaComponent implements OnInit {

  loginForm: FormGroup;
  hide = true;
  hide2 = true;
  showSpinner: boolean = false;
  showForm:boolean=true;
  strUserName:string;
  strPassword:string;
  strConfirmPassword:string;
  cookie:string;
  id:string;

  constructor(private formBuilder: FormBuilder,private storageService: StorageService,private route: ActivatedRoute,
    private router: Router,private CRespuestaService:CRespuestaService,public dialog: MatDialog) {
      this.route.queryParams.subscribe(params => {
        this.cookie=params["coo"];
        this.id=params["id"];
      });
     }

  ngOnInit() {
    this.loginForm  = this.formBuilder.group({

      username: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.maxLength(40),
        //Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),

      password: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.minLength(6),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        //Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}'),
      ])),

      confirmpassword: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.minLength(6),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        //Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}'),
      ])),

    });
  }


  keyDownFunction(event) {

    if(event.key === "Enter"){
      this.submitLogin();
    }
  }
  
submitLogin(){
  if(this.loginForm.valid){        
    
    if(this.strPassword===this.strConfirmPassword){
      this.CRespuestaService.changePassword(this.strUserName,this.strPassword,this.strConfirmPassword,this.cookie,this.id).pipe(first()).subscribe((datos:any)=>{
        //console.log(datos)
        this.mensajeError(datos.strMensaje);
        if(datos.booOk===true){
          

          this.showForm=false;
          this.showSpinner = true;
              setTimeout(() => {
                let extra: NavigationExtras = {
                  queryParams: {
                    'coo':this.cookie,
                  }
                }
                this.router.navigate(['/login'], extra);
                this.showSpinner = false;
          }, 4000);
        }
      })
    }else{
      this.mensajeError('Las contraseñas no coinciden');
    }        
  }  
 }

 private mensajeError(data:any){
  let dialogRef = this.dialog.open(MensajeErrorComponent, {
    width: '600px',
    data: {
      'strMensaje':data
    }
  });
  dialogRef.afterClosed().subscribe(() => {
    //this.listar();
  });
 }

}
