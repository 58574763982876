import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthAdminGuard } from './core/guards/auth-admin.guard';
import { FooterComponent } from './footer/footer.component';
import { InicioComponent } from './inicio/inicio.component';
import { ComandaComponent } from './comanda/comanda.component';
import { MensajeErrorComponent } from './mensaje-error/mensaje-error.component';
import { AuthorizatedGuard } from './core/guards/authorizated.guard';
import { InsertaTicketComponent } from './inserta-ticket/inserta-ticket.component';
import { TurnosComponent } from './turnos/turnos.component';
import { CambiaContrasenaComponent } from './cambia-contrasena/cambia-contrasena.component';
import { LoginrecuperacionComponent } from './loginrecuperacion/loginrecuperacion.component';
import { ErrorWifiComponent } from './error-wifi/error-wifi.component';
import { APIResolver } from 'src/app/core/interface/resolve';

const routes: Routes = [
  /************ componentes que se pueden cargar sin loguearse ***********/
  { path: 'login', component: LoginComponent },
  { path: 'recuperacioncontrasena', component: LoginrecuperacionComponent },
  { path: 'cambiocontrasena', component: CambiaContrasenaComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'gestionpedidos', component: InicioComponent },
  //{ path: 'comandas', component: ComandaComponent,canActivate: [ AuthorizatedGuard ]},
  { path: 'mensajeError', component: MensajeErrorComponent },
  { path: 'insertaticket', component: InsertaTicketComponent,canActivate: [ AuthorizatedGuard ] },
  { path: 'turnos/:coo', component: TurnosComponent, resolve: { items: APIResolver }  },
  { path: 'offline', component: ErrorWifiComponent },

  /************************* Estos son rutas por defecto ************************/

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
