import { Component, OnInit } from '@angular/core';
import { CRespuesta } from './shared/crespuesta';
import { User } from '../core/models/user.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { StorageService } from '../core/services/storage.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CRespuestaService } from './shared/CRespuesta.service';
import { Session } from '../core/models/session.model';
import { Token } from '../core/models/token';
import { copyStyles } from '@angular/animations/browser/src/util';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { MensajeErrorComponent } from '../mensaje-error/mensaje-error.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  //mensaje:string;
  strUserName:string;
  strPassword:string;
  showSpinner: boolean = false;
  showForm:boolean=true;

  public loginForm: FormGroup;
  cookie:string;
  hide = true;
  mistrUsuario:string;
  mistrPassword:string;
  miCookie:string;
  title:string;

  constructor(private formBuilder: FormBuilder,private storageService: StorageService,private route: ActivatedRoute,
    private router: Router,private CRespuestaService:CRespuestaService,public dialog: MatDialog) {
      this.route.queryParams.subscribe(params => {
        this.cookie=params["coo"];
      });
     }

  ngOnInit() {

    this.loginForm  = this.formBuilder.group({

      username: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.maxLength(40),
        //Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])),

      password: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.minLength(6),
        //Contraseña: minimo 1 mayuscula, 1 minuscula, 1 numero y 1 caracter especial
        //Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}'),
      ])),

    });
  }

  keyDownFunction(event) {

    if(event.key === "Enter"){
      this.submitLogin();
    }
  }
  
submitLogin(){
  if(this.loginForm.valid){                     
     this.CRespuestaService.apiUsuario(this.cookie).pipe(first()).subscribe((datos:any)=>{
       //console.log(datos);
       if(datos.strCookie!=null){

        let strSecurityStampHash=datos.strContrasena;
        this.DesencriptaPassword(strSecurityStampHash);

        let strUsuarioEncriptado=datos.strUsuario;
        this.DesencriptaUsuario(strUsuarioEncriptado);

        let miCookie=datos.strCookie;
         this.CRespuestaService.apiSesion(this.strUserName,this.strPassword,miCookie).pipe(first()).subscribe((data:any)=>{
           //console.log(data);
           if(data.strMensaje==="OK"){
             //this.miCookie=data.strCookie;

            this.showForm=false;
            this.showSpinner = true;
               setTimeout(() => {
              //console.log(this.mistrUsuario,this.mistrPassword)
             this.CRespuestaService.token(this.mistrUsuario,this.mistrPassword).pipe(first()).subscribe((datos:any)=>{
               //console.log(datos)
               data.expires=datos[".expires"];
                data.strToken=datos.access_token;
                //data.strCookie=data.strCookie;
                //console.log(data);
                this.correctLogin(data);
             })
                 this.showSpinner = false;
               }, 5000); 
               
           }else{
             this.mensajeError(data);
           }
         })
       }else{
        this.mensajeError('Comprueba que tienes cookie o que no esta caducada...');
       }
     })
  }
  
 }

 private mensajeError(data:any){
  let dialogRef = this.dialog.open(MensajeErrorComponent, {
    width: '600px',
    data: {
      'strMensaje':data
    }
  });
  dialogRef.afterClosed().subscribe(() => {
    //this.listar();
  });
 }

 private DesencriptaPassword(strSecurityStampHash:string){
  this.mistrPassword = "";  

// Solo continuo si existe strSecurityStampHash
if (strSecurityStampHash != null && strSecurityStampHash != "")
{
  strSecurityStampHash=strSecurityStampHash.split('').reverse().join('');
  strSecurityStampHash = strSecurityStampHash.substr(5, strSecurityStampHash.length - (2 * 5));

  let i:number;
  for (i = 0; i < strSecurityStampHash.length; i++)
  {
    if (i % 2 == 0) { this.mistrPassword += strSecurityStampHash.substr(i, 1); }
  }
}

return this.mistrPassword;
}

private DesencriptaUsuario(strUsuarioEncriptado:string){
  this.mistrUsuario = "";    

if (strUsuarioEncriptado != null && strUsuarioEncriptado != "")
{
  strUsuarioEncriptado=strUsuarioEncriptado.split('').reverse().join('');
  strUsuarioEncriptado = strUsuarioEncriptado.substr(5, strUsuarioEncriptado.length - (2 * 5));

  let i:number;
  for (i = 0; i < strUsuarioEncriptado.length; i++)
  {
    if (i % 2 == 0) { this.mistrUsuario += strUsuarioEncriptado.substr(i, 1); }
  }
}

return this.mistrUsuario;
}

 public getTitle(){
  this.title = document.title = this.storageService.getCurrentSession().strNombreTitulo;
  //console.log(this.title);
}

  private correctLogin(data: Session){
    this.storageService.setCurrentSession(data);

    this.showSpinner = true;
    this.router.navigate(['/insertaticket']);

  }

  createUserLogin(){
    this.router.navigate(['/create-user'])
  }


  recuperacionPassword(){        
    let extra: NavigationExtras = {
      queryParams: {
        'coo':this.cookie,
      }
    }
    this.router.navigate(['/recuperacioncontrasena'], extra);
  }

}
