import { Component, OnInit } from '@angular/core';
import { TurnoService } from '../core/services/turno.service';
import { first } from 'rxjs/operators';
import { StorageService } from '../core/services/storage.service';
import { NavigationExtras, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-inserta-ticket',
  templateUrl: './inserta-ticket.component.html',
  styleUrls: ['./inserta-ticket.component.scss']
})
export class InsertaTicketComponent implements OnInit {

  ticket:any;
  loginForm: FormGroup;
  //builder = new HubConnectionBuilder();

  constructor(private storageService: StorageService,private miServicio:TurnoService,private router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.loginForm  = this.formBuilder.group({

      numticket: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.maxLength(40),
        //Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ]))
    });
    //console.log(this.storageService.getCurrentSession());
  }

  keyDownFunction(event) {
//console.log(event)
    if(event.key === "Enter"){
      this.enviar();
    }
  }

  enviar(){
    //console.log(this.ticket);    
    this.miServicio.postTicket(this.ticket).pipe(first()).subscribe((datos:any)=>{
      //console.log(datos);
      if(datos.booOk===true){
        this.miServicio.postAspCore(this.ticket).pipe(first()).subscribe(datos=>{
          //console.log(datos)
        })
      }
    })
  }

  salir(){
    this.storageService.logout();
  }

}
