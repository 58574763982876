
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {StorageService} from "../services/storage.service";

@Injectable()
export class AuthorizatedGuard implements CanActivate  {

  constructor(private router:Router,  private storageService: StorageService) { }

  /*canActivate() {
    let loginUserData = this.storageService.getCurrentUser();
    if (this.storageService.isAuthenticated()) {
      return true;
    }
    this.storageService.logout();
    return false;
  }*/

  canActivate() {

    if (this.storageService.isAuthenticated()) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page
    this.router.navigate(['/login']);
    return false;
  }

}
