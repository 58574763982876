export class CTicketCola {

    public intId?:number;
    public datFechaRegistro?:Date;
    public intNTicket?:number;
    public strEstado?:string;
    public strObservaciones?:string;
    public intNTicketRecortado?:number;

}
