import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CTicketCola } from '../models/cticket-cola';

@Injectable({
  providedIn: 'root'
})
export class TurnoService {

  constructor(private http: HttpClient, private storageService: StorageService ) {}

  public url: string = environment.baseUrl+'/api/';
  private token=this.storageService.getCurrentToken();
  private cookie=this.storageService.getCurrentSession().strCookie;
  private hostSignalR=this.storageService.getCurrentSession().strHostSignalR;

  httpOptionsToken = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.token  })
  };

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };

  getTickets(cookie):Observable<CTicketCola[]>{ 
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'CTicketCola?'+dato;
    return this.http.get<CTicketCola[]>(urlCompleta,  this.httpOptions);
  }

  getTickets2(){ 
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'CTicketCola?'+dato;
    return this.http.get<CTicketCola>(urlCompleta,  this.httpOptions);
  }

  postTicket(ticket):Observable<CTicketCola>{
    let urlCompleta=this.url+'CTicketCola?'+"strCookie="+this.cookie;

    let dato:CTicketCola = {};
    dato.intNTicket=ticket;

    var jsonString = JSON.stringify(dato);

    return this.http.post<CTicketCola>(urlCompleta, jsonString, this.httpOptionsToken);    
  }

  postAspCore(num): Observable<any>{
    let url=this.hostSignalR+"/api/message";
    //let url="http://localhost:5000/api/message";
    //let url="http://192.168.1.128:5050/api/message";
    let dato = '{"Type":"'+ num +'", "Payload":""}';
    let headers = new HttpHeaders().set('Content-Type','application/json');
     
    return this.http.post<any>(url, dato, {headers: headers, responseType:'text' as 'json'});
  } 
  
}
