import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Session } from '../core/models/session.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  cookie:string;
  hostSignalR:string;
  //mesa:string;
  //datos:Session[]=[];
  private url: string = environment.baseUrl+'/api/';

  constructor(private router: Router,private route: ActivatedRoute,private http: HttpClient) {
    this.route.queryParams.subscribe(params => {
      this.cookie=params["coo"];
      //this.mesa=params["mes"];
    });
   }

  ngOnInit() {
    //console.log(this.cookie)
    sessionStorage.setItem("cookieTurnos", this.cookie);
    //this.solicitaUrlSignalR();
    //sessionStorage.setItem('intMesaIdServiciosMesa', this.mesa);

    setTimeout(() => {
      //cargo los datos de la cookie en la sesion y redirijo a la carta online
      //this.correctLogin(this.datos);
      this.router.navigate(['/turnos', this.cookie]);
    }, 4000);
  }

  solicitaUrlSignalR(){
    this.getUrlSignalR().pipe(first()).subscribe((datos:any)=>{
      this.hostSignalR=datos.strValor;
      //console.log(this.hostSignalR)
      sessionStorage.setItem('signalRTurnosClientes', this.hostSignalR);      
    })
    //console.log(this.hostSignalR)
    //return this.hostSignalR=this.getUrlSignalR();
  }

  getUrlSignalR(){    
    let url=this.url+"Establecimiento/signalR?Close=true";
    let headers = new HttpHeaders().set('Content-Type','application/json');      
    return this.http.get(url, {headers: headers});    
  }

}
