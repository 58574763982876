import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { ComandaService } from '../core/services/comanda.service';
import { first } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Resultcomanda } from '../core/models/resultcomanda';
import { Estadolineacomada } from '../core/models/estadolineacomada';

@Component({
  selector: 'app-comanda',
  templateUrl: './comanda.component.html',
  styleUrls: ['./comanda.component.scss']
})
export class ComandaComponent implements OnInit {

  date = new FormControl(new Date());
  miFecha =  new Date();
  misComandas:Resultcomanda[]=[];
  dataTodo:Resultcomanda[]=[];
  listEstadosComanda:Estadolineacomada[]=[];
  button1:boolean=true;
  button2:boolean=false;
  holder:string;
  @ViewChild('myDiv') myDiv: ElementRef;


  constructor(private storageService: StorageService, private miServicio:ComandaService,public datepipe: DatePipe) { }

  ngOnInit() {
    //console.log(this.storageService.getCurrentSession())
    
    this.listarEstadosComanda();
    this.listarComandas();
    
    //console.log(this.date.value.toLocaleDateString());
  }

  pulsa(event){    
    //console.log(event);
  }

  listarComandas(){
    let miFechaConvert=this.datepipe.transform(this.miFecha, 'yyyy-MM-dd'); 
    //console.log(miFechaConvert)
    this.miServicio.getComandas(miFechaConvert,'N').pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.misComandas=datos;

      /*this.misComandas.forEach(element => {
        if(element.miCTicket.strComandaFinalizada==="N"){
          document.getElementById((element.miCTicket.n_ticket).toString()).style.display="inline-block";
          document.getElementById((element.miCTicket.id_ticket).toString()).style.display="none";
        }

      });*/

      //console.log(this.misComandas)
    })
  }

  listarComandasFinalizadas(){
    let miFechaConvert=this.datepipe.transform(this.miFecha, 'yyyy-MM-dd'); 
    //console.log(miFechaConvert)
    this.miServicio.getComandas(miFechaConvert,'S').pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.misComandas=datos;

      /*this.misComandas.forEach(element => {
        if(element.miCTicket.strComandaFinalizada==="N"){
          document.getElementById((element.miCTicket.n_ticket).toString()).style.display="inline-block";
          document.getElementById((element.miCTicket.id_ticket).toString()).style.display="none";
        }

      });*/

      //console.log(this.misComandas)
    })
  }

  listarEstadosComanda(){
    this.miServicio.getEstadosComandaLinea().pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listEstadosComanda=datos;          
      //console.log(this.listEstadosComanda);
    })
  }

  cambiaLineaTicket(cl){
    //console.log(cl);
    this.miServicio.putLineaTicket(cl).pipe(first()).subscribe((datos:any)=>{
      //console.log(datos);
      
      let myObject:any;
      myObject=datos[0];
      //console.log(myObject);

      let miTicket=cl.id_ticket;

      var index = this.misComandas.map(x => {
        return x.miCTicket.id_ticket;
      }).indexOf(miTicket);
      //console.log(miTicket)

      if(datos.length!=0){      
        this.misComandas.splice(index, 1, myObject);
        //console.log(index);
        //console.log(this.misComandas.splice(index, 1, myObject));
        //console.log(this.misComandas);
      }

      if(datos.length===0){
        this.misComandas.splice(index, 1);
        //console.log(index);
        //console.log('llega')
        //console.log(this.misComandas.splice(index, 1));
      } 

    })
  }

  getColor(strEstadoComanda) { 
    switch (strEstadoComanda) {
      case 'P':
        return '';
      case 'X':
        return '#ffc14d';
      case 'F':
        return '#00b300';
    }
  }

  prepararComanda(c){
    //console.log(c);
    if(c.strTestoBoton==="Preparar"){
      this.miServicio.putTicket(c,'X').pipe(first()).subscribe((datos:any)=>{
        //console.log(datos)
        let myObject:any;
        myObject=datos[0];
  
        let miTicket=c.miCTicket.id_ticket;
  
        var index = this.misComandas.map(x => {
          return x.miCTicket.id_ticket;
        }).indexOf(miTicket);
  
        if(datos.length!=0){      
          this.misComandas.splice(index, 1, myObject);
        }
      });
    }if(c.strTestoBoton==="Terminar"){
      this.miServicio.putTicket(c,'F').pipe(first()).subscribe((datos:any)=>{
        //console.log(datos);
        let myObject:any;
        myObject=datos[0];
  
        let miTicket=c.miCTicket.id_ticket;
  
        var index = this.misComandas.map(x => {
          return x.miCTicket.id_ticket;
        }).indexOf(miTicket);
  
        if(datos.length===0){      
          this.misComandas.splice(index, 1);
        }
      });
    }
    
    //this.myDiv.nativeElement.innerHTML="Terminar";
    //document.getElementById(c.miCTicket.id_ticket).innerHTML="Terminar";
    //document.getElementById(c.miCTicket.n_ticket).style.display="none";
  }

  finalizarComanda(c){
    //console.log('boton2')
    /*this.miServicio.putTicket(c,'F').pipe(first()).subscribe((datos:any)=>{
      //console.log(datos);
      let myObject:any;
      myObject=datos[0];

      let miTicket=c.miCTicket.id_ticket;

      var index = this.misComandas.map(x => {
        return x.miCTicket.id_ticket;
      }).indexOf(miTicket);

      if(datos.length!=0){      
        this.misComandas.splice(index, 1, myObject);
      }
    });*/
    //document.getElementById(c.miCTicket.n_ticket).style.display="inline-block";
    //document.getElementById(c.miCTicket.id_ticket).style.display="none";
  }

  pendientes(){
    document.getElementById('pendiente').style.color="#666666";
    document.getElementById('finalizada').style.color="#b3b3b3";
    this.listarComandas();
    //document.getElementById('pendiente').style.borderBottomColor="orange";
  }

  finalizadas(){
    document.getElementById('finalizada').style.color="#666666";
    document.getElementById('pendiente').style.color="#b3b3b3";
    this.listarComandasFinalizadas();
  }

  salir(){
    //this.storageService.logout();
  }

}
