import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { TurnoService } from '../core/services/turno.service';
import { first } from 'rxjs/operators';
import { CTicketCola } from '../core/models/cticket-cola';
import { WebSocketSubject } from 'rxjs/observable/dom/WebSocketSubject';
import { Observable } from 'rxjs';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { GestionturnosService } from '../core/services/gestionturnos.service';
import { Establecimiento } from '../core/models/establecimiento';
import { NavigationExtras, Router, Route, ActivatedRoute } from '@angular/router';
import { ErrorWifiComponent } from '../error-wifi/error-wifi.component';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-turnos',
  templateUrl: './turnos.component.html',
  styleUrls: ['./turnos.component.scss']
})
export class TurnosComponent implements OnInit, OnDestroy {

  listTickets:CTicketCola[]=[];
  ultimoTicket:CTicketCola=new CTicketCola();
  comments: Observable<CTicketCola>
  listTurnos:CTicketCola[]=[];
  miUltimoTicket:CTicketCola=new CTicketCola();
  //@ViewChild('myDiv') viewer: ElementRef;
  private _hubConnection: HubConnection;
  miCookie:string;
  listEstablecimiento:Establecimiento[]=[];

  constructor( private miServicio:GestionturnosService,private router:Router,private route: ActivatedRoute,public dialog: MatDialog) { }

  ngOnInit() {            
    //console.log(sessionStorage.getItem("signalRTurnosClientes"));
    //console.log(this.route.snapshot.data.items.strValor);

    this.miCookie=sessionStorage.getItem("cookieTurnos");
    this.datosEstablecimiento();

    this._hubConnection = new HubConnectionBuilder().withUrl(this.route.snapshot.data.items.strValor + '/notifyhub').build();
    //this._hubConnection = new HubConnectionBuilder().withUrl(sessionStorage.getItem("signalRTurnosClientes")+'/notifyhub').build();
    //this._hubConnection = new HubConnectionBuilder().withUrl("http://localhost:5000/notifyhub").build();
    //this._hubConnection = new HubConnectionBuilder().withUrl("http://signalrhub.infitec.es:8080/notifyhub").build();
    //this._hubConnection = new HubConnectionBuilder().withUrl("http://webservice.infitec.es/signalrhub/notifyhub").build();
    this._hubConnection
      .start()
      .then()
  .catch();

    this._hubConnection.on('BroadcastMessage', (type: any, payload: string) => {
      this.miUltimoTicket.intNTicketRecortado=type;
      this.listarTickets();
    });

    /*this._hubConnection.off('BroadcastMessage', (type: any, payload: string) => {
      console.log('off');
    });*/

    /*
    ****************************______  ESTO ME INDICA EL TIEMPO QUE SE MANTIENE ABIERTA LA CONEXION ESPERANDO UNA RESPUESTA  _____********************************
    SI NO RECIBE RESPUESTA CIERRA LA CONEXION
    TAMBIEN PUEDO DEFINIR AQUI EL TIEMPO DE CONEXION QUE QUIERO
    console.log(this._hubConnection.serverTimeoutInMilliseconds);
    this._hubConnection.serverTimeoutInMilliseconds=3000;
    */

    this._hubConnection.onclose(()=>{
      //console.log('onclose')
      //alert('se ha perdido la conexion de internet');
      let dialogRef = this.dialog.open(ErrorWifiComponent, {
        width: '600px'      
      });
      dialogRef.afterClosed().subscribe(() => {
        //this.router.navigate(['/gestionpedidos']);
        /*let extra:NavigationExtras={
          queryParams:{
            'coo':sessionStorage.getItem("cookieTurnos"),
          }
        }
    
        this.router.navigate(['/gestionpedidos'],extra);*/
        window.location.reload();
      });

      /******************************___________  PROCESO PARA INTENTAR RESTABLECER LA CONEXION  ___________***************************************/
      /*setTimeout(function(){        
        this._hubConnection.start().then().catch();

        this._hubConnection.on('BroadcastMessage', (type: any, payload: string) => {
        this.miUltimoTicket.intNTicketRecortado=type;
        this.listarTickets();
      });
      },3000); */

    });

    if(this.miUltimoTicket.intNTicketRecortado===undefined){
      this.listarTickets();
    }
  }

  ngOnDestroy(){
    this._hubConnection.stop();
  }


  listarTickets(){
    //console.log(sessionStorage.getItem("cookieTurnos"));
    this.miServicio.getTickets(this.miCookie).pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listTickets.splice(0, this.listTickets.length)    
      this.ultimoTicket=datos[0];
      for(let i=1; i<datos.length; i++){
        this.listTickets.push(datos[i]);
      }  
    })

  }


  datosEstablecimiento(){
    //console.log(this.miCookie)
    this.miServicio.getEstablecimiento(this.miCookie).pipe(first()).subscribe(datos=>{
      //console.log(datos);
      this.listEstablecimiento=datos;
      //console.log(this.listEstablecimiento)
    })
  }


  login(){
    //console.log(this.miCookie)
    /*let extra:NavigationExtras={
      queryParams:{
        'coo':this.miCookie,
      }
    }

    this.router.navigate(['/login'],extra)*/

    //console.log(window.location.origin+'/login?coo='+this.miCookie)

    window.open(window.location.origin+'/login?coo='+this.miCookie)
  }

  /*private getDiff(): number {
    const nativeElement = this.viewer.nativeElement;
    return nativeElement.scrollHeight - (nativeElement.scrollTop + nativeElement.clientHeight);
}

private scrollToBottom(t = 1, b = 0): void {
    if (b < 1) {
        b = this.getDiff();
    }
    if (b > 0 && t <= 120) {
        setTimeout(() => {
            const diff = this.easeInOutSin(t / 120) * this.getDiff();
            this.viewer.nativeElement.scrollTop += diff;
            this.scrollToBottom(++t, b);
        }, 1 / 60);
    }
}

private easeInOutSin(t): number {
    return (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2;
}*/
  

}
