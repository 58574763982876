import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { Resultcomanda } from '../models/resultcomanda';
import { Estadolineacomada } from '../models/estadolineacomada';
import { CTicketLinea } from '../models/cticket-linea';

@Injectable({
  providedIn: 'root'
})
export class ComandaService {

  constructor(private http: HttpClient, private storageService: StorageService ) {}

  public url: string = environment.baseUrl+'/api/';
  private token=this.storageService.getCurrentToken();
  private cookie=this.storageService.getCurrentSession().strCookie;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.token  })
  };

  getComandas(miFechaConvert,strFinalizada):Observable<Resultcomanda[]>{
    let dato='strCookie='+this.cookie+'&strTicketId='+'&strPeriodo='+'&strCaja=';
    //dato=dato+'&strFechaDesde='+miFechaConvert+'&strFechaHasta='+miFechaConvert+'&strParametroLike=';
    dato=dato+'&strFechaDesde=2018-03-29&strFechaHasta=2018-03-29&strParametroLike=';
    dato=dato+'&strTicketIdLinea='+'&strParametroLikeLinea='+'&strFinalizado='+strFinalizada;
    let urlCompleta=this.url+'CTicket?'+dato;
 
    return this.http.get<Resultcomanda[]>(urlCompleta,this.httpOptions);
  }

  getEstadosComandaLinea():Observable<Estadolineacomada[]>{
    let dato='strCookie='+this.cookie;
    let urlCompleta=this.url+'EstadoLineaComanda?'+dato;
 
    return this.http.get<Estadolineacomada[]>(urlCompleta,this.httpOptions);
  }

  putLineaTicket(cl:CTicketLinea):Observable<Resultcomanda>{
    let urlCompleta=this.url+'CTicket?strCookie='+this.cookie+'&strTicketId='+cl.id_ticket;
    urlCompleta=urlCompleta+'&strTicketIdLinea='+cl.id_ticket_linea+'&strEstadoComanda=';
    //let dato = '{"intId":"'+ limpieza.intId +'", "intEstadoLimpiezaId":'+ limpieza.intEstadoLimpiezaId +', "strNotas":"'+ limpieza.strNotas +'", "strHoraInicio":"'+ limpieza.strHoraInicio +'", "strHoraFin":"'+ limpieza.strHoraFin +'"}'; 

    return this.http.get<Resultcomanda>(urlCompleta, this.httpOptions);
  }

  putTicket(c:Resultcomanda,strEstado):Observable<Resultcomanda>{
    let urlCompleta=this.url+'CTicket?strCookie='+this.cookie+'&strTicketId='+c.miCTicket.id_ticket;
    urlCompleta=urlCompleta+'&strTicketIdLinea=&strEstadoComanda='+strEstado;
    //let dato = '{"intId":"'+ limpieza.intId +'", "intEstadoLimpiezaId":'+ limpieza.intEstadoLimpiezaId +', "strNotas":"'+ limpieza.strNotas +'", "strHoraInicio":"'+ limpieza.strHoraInicio +'", "strHoraFin":"'+ limpieza.strHoraFin +'"}'; 

    return this.http.get<Resultcomanda>(urlCompleta, this.httpOptions);
  }

}
