import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-wifi',
  templateUrl: './error-wifi.component.html',
  styleUrls: ['./error-wifi.component.scss']
})
export class ErrorWifiComponent implements OnInit {

  //mensaje:string;

  constructor(private router:Router,public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    //this.mensaje=data.strMensaje;
   }

  ngOnInit() {
    //console.log(this.data);
    //this.mensaje
  }

  close(){
    this.thisDialogRef.close();
    //this.router.navigate(['/login']);
  }

}
