import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Session} from "../../core/models/session.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CreateUser } from "../../core/models/create-user";
import { environment } from "../../../environments/environment";
import { Configuraciones } from "../../core/models/configuraciones";

@Injectable()
export class CRespuestaService {

constructor(private http: HttpClient) {}

public url: string = environment.baseUrl;

/*findCRespuesta(strUserName:string,strPassword:string): Observable<any>{
    
  var data = "username=" + strUserName + "&password=" + strPassword + "&grant_type=password";
  var reqHeader =new HttpHeaders().set('Content-Type','application/json');
  return this.http.post<any>(this.url + '/token' , data, { headers: reqHeader });
}

findSession(miUserName,miToken):Observable<Session>{

  var headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + miToken });
  var body = '{"strUsuarioNombre": "'+ miUserName +'"}';
  return this.http.post<Session>(this.url + '/api/sesion', body, { headers: headers })
}
*/

apiUsuario(cookie): Observable<any>{
  let dato = '{"strId":"'+ cookie +'"}';
  let headers = new HttpHeaders().set('Content-Type','application/json');
   
  return this.http.post<any>(this.url + '/api/usuario', dato, {headers: headers});
}

apiSesion(strUsuario,strPassword,miCookie): Observable<any>{
  let dato = '{"strUsuario": "'+ strUsuario +'", "strContrasena":"'+ strPassword +'", "strCookie":"'+ miCookie +'"}';
  let headers = new HttpHeaders().set('Content-Type','application/json');   
  return this.http.post<any>(this.url + '/api/sesion/turnoscocina', dato, {headers: headers});
}

token(mistrUsuario,mistrPassword): Observable<any>{
  var data = "username=" + mistrUsuario + "&password=" + mistrPassword + "&grant_type=password";
  var reqHeader =new HttpHeaders().set('Content-Type','application/json');
  return this.http.post<any>(this.url + '/token' , data, { headers: reqHeader });
}

addUser(newUser:CreateUser){
  let dato = '{"TipoUsuario": '+ newUser.intTipoUsuario +', "Email": "'+ newUser.strEmail +'", "Password":"'+ newUser.strPassword +'", "ConfirmPassword":"'+ newUser.strConfirmPassword +'", "strUsuarioNombre":"'+ newUser.strNombre +'", "strUsuarioApellidos":"'+ newUser.strApellidos +'", "strUsuarioDni":"'+ newUser.strDni +'", "strUsuarioFechaNacimiento":"'+ newUser.strFechaNacimiento +'"}';
  let json=dato;
  let params = json; 
  let headers = new HttpHeaders().set('Content-Type','application/json');     
  return this.http.post(this.url + '/api/account/register', params, {headers: headers});
}

sendEmail(id){

  let params = 'strCookieId='+id+'';
  //let params = dato; 
  let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');     
  return this.http.put(this.url + '/api/sesion?'+ params, {headers: headers});

}


getConfiguraciones(miOtroToken):Observable<Configuraciones>{
  //let token=this.storageService.getCurrentToken();
  var headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + miOtroToken });
  //let dato='intClienteId='+ intClienteId +'&intTarjetaId='+ intTarjetaId +'&intRecargaId='+intRecargaId;
  let urlCompleta=this.url+'/api/configuracion?';

  return this.http.get<Configuraciones>(urlCompleta,  { headers: headers });
}


envialEmailRecuperacionContrasena(strUserName,cookie):Observable<any>{
  let headers =new HttpHeaders().set('Content-Type','application/json');
  let urlCompleta=this.url+'/api/establecimiento/forgotPassword?'+"strCookie="+cookie;
  let dato = '{"Email": "'+ strUserName +'"}'; 

  return this.http.post<any>(urlCompleta , dato, { headers: headers });
}

changePassword(strUserName,strPassword,ConfirmPassword,cookie,id):Observable<any>{
  var reqHeader =new HttpHeaders().set('Content-Type','application/json');
  let urlCompleta=this.url+'/api/establecimiento/SetPassword?'+"strCookie="+cookie+"&strId="+id;
  let dato = '{"strUserName": "'+ strUserName +'", "strPassword":"' + strPassword + '", "ConfirmPassword":"' + ConfirmPassword + '"}'; 

  return this.http.post<any>(urlCompleta , dato, { headers: reqHeader });
}

}