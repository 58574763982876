import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { GestionturnosService } from '../services/gestionturnos.service';

@Injectable()
export class APIResolver implements Resolve<any> {
  constructor(private apiService: GestionturnosService,private storageService: StorageService) {}

  resolve(route: ActivatedRouteSnapshot) {
      //console.log(route.params);

      //paso la cookie
      if(route.params.coo!=null){
        return this.apiService.getUrlSignalR();
      }               
  }
}
