
import {Injectable} from "@angular/core";
import { Router, NavigationExtras } from '@angular/router';
import {Session} from "../models/session.model";
import {User} from "../models/user.model";
import { Token } from "../models/token";

@Injectable()
export class StorageService {

  /*private localStorageService;
  private currentSession : Session = null;
  private isAdmininistrador:boolean=true;

  constructor(private router: Router) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
  }

  setCurrentSession(session: Session): void {
    this.currentSession = session;
    this.localStorageService.setItem('loginGestionTurnos', JSON.stringify(session));
  }

  loadSessionData(): Session{
    var sessionStr = this.localStorageService.getItem('loginGestionTurnos');
    return (sessionStr) ? <Session> JSON.parse(sessionStr) : null;
  }

  getCurrentSession(): Session {
    return this.currentSession;
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('loginGestionTurnos');
    this.currentSession = null;
  }

  getCurrentUser(): User {
    var session: Session = this.getCurrentSession();
    return (session && session.user) ? session.user : null;
  };

  isAuthenticated(): boolean {
    return (this.getCurrentUser() != null) ? true : false;
  };

  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.strToken) ? session.strToken : null;
  };

  getToken(): Token {
    var session = this.getCurrentSession();
    return (session && session.token) ? session.token : null;
  };

  getTitulo():string{
    var session: Session = this.getCurrentSession();
    return (session && session.strNombreTitulo) ? session.strNombreTitulo : null;
  }

  getNombre():string{
    var session: Session = this.getCurrentSession();
    return (session && session.strNombre) ? session.strNombre : null;
  }

  logout(): void{
    
    let extra: NavigationExtras = {
      queryParams: {
        'coo':this.getCurrentSession().strCookie,
      }
  }
    this.router.navigate(["/login"],extra);

    this.removeCurrentSession();
  }*/


  private localStorageService;
  private currentSession : Session = null;

  constructor(private router: Router) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
  }

  setCurrentSession(session: Session): void {
    this.currentSession = session;
    this.localStorageService.setItem('currentloginTurnos', JSON.stringify(session));
    //this.localStorageService.setItem('cookieUser', JSON.stringify(session.strCookie));
  }

  loadSessionData(): Session{
    var sessionStr = this.localStorageService.getItem('currentloginTurnos');
    //var sessionStr2 = this.localStorageService.getItem('cookieUser');
    return (sessionStr) ? <Session> JSON.parse(sessionStr) : null;
    //(sessionStr2) ? <any> JSON.parse(sessionStr2) : null;
  }

  getCurrentSession(): Session {
    return this.currentSession;
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentloginTurnos');
    //this.localStorageService.removeItem('cookieUser');
    this.currentSession = null;
  }

  getCurrentUser(): User {
    var session: Session = this.getCurrentSession();
    return (session && session.user) ? session.user : null;
  };

  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null) ? true : false;
  };

  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.strToken) ? session.strToken : null;
  };

  logout(): void{
    
    //this.router.navigate(['/login']);
    //console.log(this.getCurrentSession().strCookie);

    let extra: NavigationExtras = {
      queryParams: {
        'coo':this.getCurrentSession().strCookie,
      }
  }
    this.router.navigate(["/login"],extra);

    this.removeCurrentSession();
  }

}
