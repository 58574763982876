import { Component, OnInit } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { first } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BotonesService } from '../core/services/botones.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  //cookieNombre = 'ComandasCocina';

  //NO PUEDO COGER EL NOMBRE DE LA SESION PORQUE EN EL LOGIN NO HAY SESION INICIADA
  //nombreApp:string=this.storageService.getCurrentSession().strNombre;
  nombreApp:string;
  tituloApp:string;
  aceptCookies:boolean=false;
  //IsCookieExists:boolean=this.cookieService.check(this.cookieNombre);


  constructor(private storageService: StorageService,private miservicio:BotonesService,
    private cookieService: CookieService ) { }

  ngOnInit() {
    //console.log(this.storageService.getCurrentSession())
    //this.getNombreApp();
/*
    if(this.IsCookieExists===true){
      this.aceptCookies=false;
    }*/

    //this.cookieService.delete('Test');
    //console.log(this.cookieValue2);    
  }

  getNombreApp(){    
    /*this.miservicio.getNombreApp().pipe(first()).subscribe(datos => { 
      //console.log(datos);      
      this.nombreApp=datos[0].strNombre;
      this.tituloApp=document.title=datos[0].strNombreTitulo;
    })*/
  }

/*
  aceptaCookies(){
    this.cookieService.set( this.cookieNombre, 'ServiciosMesa',365,'',window.location.hostname );
    this.aceptCookies=false;
  }

  cookies(){
    window.open('//google.com');
  }*/

}
