import { Injectable } from '@angular/core';
import { CTicketCola } from '../models/cticket-cola';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Establecimiento } from '../models/establecimiento';

@Injectable({
  providedIn: 'root'
})
export class GestionturnosService {

  public url: string = environment.baseUrl+'/api/';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient) { }


  getTickets(cookie):Observable<CTicketCola[]>{ 
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'CTicketCola?'+dato;
    return this.http.get<CTicketCola[]>(urlCompleta,  this.httpOptions);
  }

  getEstablecimiento(cookie):Observable<Establecimiento[]>{
    let dato='strCookie='+cookie+'&datoAdicional=1';
    let urlCompleta=this.url+'Establecimiento?'+dato;
    return this.http.get<Establecimiento[]>(urlCompleta,  this.httpOptions);
  }

  getUrlSignalR(){    
    let url=this.url+"Establecimiento/signalR?Close=true";
    let headers = new HttpHeaders().set('Content-Type','application/json');      
    return this.http.get(url, {headers: headers});    
  }

}
